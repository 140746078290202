import styled from '@emotion/styled'
import tw from 'twin.macro'

export const SocialNetworks = styled.ul`
  ${tw`
    flex
    space-x-5
  `}
  a {
    ${tw`
      text-gray-400
      hover:text-gray-500
    `}
    span {
      ${tw`
        sr-only
      `}
    }
    svg {
      ${tw`
        w-5
        h-5
      `}
    }
  }
`

export const NameAndRole = styled.div`
  ${tw`
    text-lg
    leading-6
    space-y-1
  `}
  h3 {
    ${tw`
      font-bold
      text-purple-900
    `}
  }
  p {
    ${tw`
      font-normal
      text-purple-700
    `}
  }
  a {
    ${tw`
      font-normal
      text-purple-500
      hover:text-purple-900
    `}
  }
`

export const TextWrapper = styled.div`
  ${tw`
    pb-10 
    pt-6
    px-6 
    xl:px-10 
    xl:text-left
    flex
    flex-col
    justify-between
  `}
`

export const Avatar = styled.div`
  .avatar {
    ${tw`
      w-full
      h-48
      object-cover
      rounded-t-lg
    `}
  }
`

export const GridList = styled.ul`
  ${tw`
    space-y-12
    sm:grid
    sm:grid-cols-2
    sm:gap-x-6
    sm:gap-y-12
    sm:space-y-0
    lg:grid-cols-4
    lg:gap-x-8
  `}
  > li {
    ${tw`
      bg-white
      rounded-lg
      overflow-hidden
      shadow-lg
    `}
  }
  div {
    ${tw`
      space-y-4
    `}
  }
`

export const SectionWrapper = styled.div`
  ${tw`
    space-y-12
  `}
`

export const SectionTitle = styled.div`
  ${tw`
    space-y-5
    sm:space-y-4
    md:max-w-xl
    lg:max-w-3xl
    xl:max-w-none
  `}
  h2 {
    ${tw`
      text-3xl
      leading-tight
      md:leading-normal
      font-extrabold
      tracking-tight
      text-purple-900
      sm:text-4xl
    `}
  }
  p {
    font-size: 1.3rem;
    line-height: 2rem;
    letter-spacing: 0.015rem;
  }
`

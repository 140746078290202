import React from 'react'
import Layout from '../../components/layout'
import CenterHero from '../../components/hero/center_hero'
import SectionWrapper from '../../components/sectionWrapper'
import SectionLogosBase from '../../components/section_logos_base'
import CallToActionSection from '../../components/call_to_action_section'
import SectionTeam from '../../components/section_team'
import { investorLogos } from '../../cdn_images/investors'

const InvestorsPage = ({ pageContext: { data, localePath } }: any) => {
  return (
    <Layout
      helmetTitle={data.header_title.raw[0].text}
      helmetDescription={data.meta_description}
      helmetImage={data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}/`}
    >
      <CenterHero
        title={data.header_title.raw}
        description={data.header_description.raw}
        textAlignment="center"
        call_to_action_color={data.slug}
      />
      <SectionWrapper color="dark-blue" disabledPadding="padding-auto">
        <SectionLogosBase items={investorLogos} />
      </SectionWrapper>
      <SectionWrapper color="light-blue" disabledPadding="padding-auto">
        <SectionTeam title={data.body[0].primary.title_section.raw} items={data.body[0].items} />
      </SectionWrapper>
      <CallToActionSection callToActionSectionData={data.body[1]} />
    </Layout>
  )
}

export default InvestorsPage

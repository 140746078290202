export const investorPictures = [
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533486/Cryptr%20www/Landing%20pages/Investors/Pictures/Marc_Sabatier_dlsmbc.jpg',
    alt: 'Marc Sabatier',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/50_Partners_la6b2o.jpg',
    alt: '50 Partners',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650526792/Cryptr%20www/Landing%20pages/Investors/Pictures/cyber_impact_picture_dijbf2.jpg',
    alt: 'Cyber Impact',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Alexei_Chemenda_p8n1v9.jpg',
    alt: 'Alexei Chemenda',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Guillaume_Luccisano_my25ri.jpg',
    alt: 'Guillaume Luccisano',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533427/Cryptr%20www/Landing%20pages/Investors/Pictures/Jonathan_Bordereau_ltd1sz.jpg',
    alt: 'Jonathan Bordereau',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Guillaume_Lestrade_j7d2wa.jpg',
    alt: 'Guillaume Lestrade',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Louis-Paul_Dareau_j04sms.jpg',
    alt: 'Louis-Paul Dareau',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533196/Cryptr%20www/Landing%20pages/Investors/Pictures/Cyril_Chemla_es0rvh.jpg',
    alt: 'Cyril Chemla',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Gregoire_Delpit_trkv9f.jpg',
    alt: 'Grégoire Delpit',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Nicolas_Dessaigne_nubveg.jpg',
    alt: 'Nicolas Dessaigne',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650526776/Cryptr%20www/Landing%20pages/Investors/Pictures/zinal_picture_yjeagk.jpg',
    alt: 'Zinal Growth',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533290/Cryptr%20www/Landing%20pages/Investors/Pictures/Emmanuel_Touboul_dx0olp.jpg',
    alt: 'Emmanuel Touboul',
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533366/Cryptr%20www/Landing%20pages/Investors/Pictures/Jean_Baptiste_Aviat_y9e9p4.jpg',
    alt: 'Jean-Baptiste Aviat',
  },
]

export const investorLogos = [
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1641533071/Cryptr%20www/Landing%20pages/Investors/Logos/logo_algolia_jbh19q.svg',
      website_link: 'https://www.algolia.com/',
      alt: 'Algolia',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1641533071/Cryptr%20www/Landing%20pages/Investors/Logos/Group_1_k4o3sl.svg',
      website_link: 'https://www.checkout.com/',
      alt: 'Checkout',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1641533071/Cryptr%20www/Landing%20pages/Investors/Logos/50_partners_pzuoxz.svg',
      website_link: 'https://www.50partners.fr/',
      alt: '50 Partners',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1656333813/Cryptr%20www/Landing%20pages/Investors/Logos/processout_logo_xdjrst.svg',
      website_link: 'https://www.processout.com/',
      alt: 'ProcessOut',
    },
  },
  {
    partner_logo: {
      url: 'https://res.cloudinary.com/cryptr/image/upload/v1641533070/Cryptr%20www/Landing%20pages/Investors/Logos/cyber_impact_kc4dmv.svg',
      website_link: 'https://cyberimpactventures.com/',
      alt: 'Cyber Impact',
    },
  },
]

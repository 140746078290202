import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const investorPictures = [
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533486/Cryptr%20www/Landing%20pages/Investors/Pictures/Marc_Sabatier_dlsmbc.jpg',
    alt: 'Marc Sabatier',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1650533486/Cryptr%20www/Landing%20pages/Investors/Pictures/Marc_Sabatier_dlsmbc.jpg"
        alt="Marc Sabatier"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/50_Partners_la6b2o.jpg',
    alt: '50 Partners',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/50_Partners_la6b2o.jpg"
        alt="50 Partners"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650526792/Cryptr%20www/Landing%20pages/Investors/Pictures/cyber_impact_picture_dijbf2.jpg',
    alt: 'Cyber Impact',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1650526792/Cryptr%20www/Landing%20pages/Investors/Pictures/cyber_impact_picture_dijbf2.jpg"
        alt="Cyber Impact"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Alexei_Chemenda_p8n1v9.jpg',
    alt: 'Alexei Chemenda',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Alexei_Chemenda_p8n1v9.jpg"
        alt="Alexei Chemenda"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Guillaume_Luccisano_my25ri.jpg',
    alt: 'Guillaume Luccisano',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Guillaume_Luccisano_my25ri.jpg"
        alt="Guillaume Luccisano"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533427/Cryptr%20www/Landing%20pages/Investors/Pictures/Jonathan_Bordereau_ltd1sz.jpg',
    alt: 'Jonathan Bordereau',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1650533427/Cryptr%20www/Landing%20pages/Investors/Pictures/Jonathan_Bordereau_ltd1sz.jpg"
        alt="Jonathan Bordereau"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Guillaume_Lestrade_j7d2wa.jpg',
    alt: 'Guillaume Lestrade',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Guillaume_Lestrade_j7d2wa.jpg"
        alt="Guillaume Lestrade"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Louis-Paul_Dareau_j04sms.jpg',
    alt: 'Louis-Paul Dareau',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Louis-Paul_Dareau_j04sms.jpg"
        alt="Louis-Paul Dareau"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533196/Cryptr%20www/Landing%20pages/Investors/Pictures/Cyril_Chemla_es0rvh.jpg',
    alt: 'Cyril Chemla',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1650533196/Cryptr%20www/Landing%20pages/Investors/Pictures/Cyril_Chemla_es0rvh.jpg"
        alt="Cyril Chemla"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Gregoire_Delpit_trkv9f.jpg',
    alt: 'Grégoire Delpit',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Gregoire_Delpit_trkv9f.jpg"
        alt="Grégoire Delpit"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Nicolas_Dessaigne_nubveg.jpg',
    alt: 'Nicolas Dessaigne',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1641529343/Cryptr%20www/Landing%20pages/Investors/Pictures/Nicolas_Dessaigne_nubveg.jpg"
        alt="Nicolas Dessaigne"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650526776/Cryptr%20www/Landing%20pages/Investors/Pictures/zinal_picture_yjeagk.jpg',
    alt: 'Zinal Growth',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1650526776/Cryptr%20www/Landing%20pages/Investors/Pictures/zinal_picture_yjeagk.jpg"
        alt="Zinal Growth"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533290/Cryptr%20www/Landing%20pages/Investors/Pictures/Emmanuel_Touboul_dx0olp.jpg',
    alt: 'Emmanuel Touboul',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1650533290/Cryptr%20www/Landing%20pages/Investors/Pictures/Emmanuel_Touboul_dx0olp.jpg"
        alt="Emmanuel Touboul"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1650533366/Cryptr%20www/Landing%20pages/Investors/Pictures/Jean_Baptiste_Aviat_y9e9p4.jpg',
    alt: 'Jean-Baptiste Aviat',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1650533366/Cryptr%20www/Landing%20pages/Investors/Pictures/Jean_Baptiste_Aviat_y9e9p4.jpg"
        alt="Jean-Baptiste Aviat"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
  {
    url: 'https://res.cloudinary.com/cryptr/image/upload/v1673963504/Cryptr%20www/Landing%20pages/Investors/Pictures/antoine_ayoub_a7onaa.jpg',
    alt: 'Antoine Ayoub',
    img: (
      <StaticImage
        src="https://res.cloudinary.com/cryptr/image/upload/v1673963504/Cryptr%20www/Landing%20pages/Investors/Pictures/antoine_ayoub_a7onaa.jpg"
        alt="Antoine Ayoub"
        imgStyle={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
        quality={90}
        formats={['auto', 'webp', 'avif']}
        placeholder="none"
        className="avatar"
      />
    ),
  },
]
